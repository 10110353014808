import React, { useRef, useLayoutEffect } from 'react';
import './feature7.css';
import { gsap } from "gsap";
import{ ScrollTrigger } from "gsap/ScrollTrigger";
import Blackboard from '../../assets/home/ux-01-web.jpg';
import Fists from '../../assets/home/ux-02-web.jpg';
import Sunshine from '../../assets/home/ux-03-web.jpg';
import { useTranslation } from 'react-i18next';

function Feature7() {
  gsap.registerPlugin(ScrollTrigger);

  const userx = useRef();
  const presentation = useRef();
  const blackboard = useRef();
  const fists = useRef();
  const sunshine = useRef();
  const {t} = useTranslation();
  let tl_presentation = useRef();
  let tl_images = useRef();

  useLayoutEffect(() => {
    gsap.from(userx.current, {
      scale: 10,
      duration: 10,
      delay: 0,
      ease: "elastic.out(1, 0.3)",
      scrollTrigger: {
        id:'3409',
        trigger: ".yasyt__features-userx__title",
        start:"top bottom",
        end: "+=200 top",
        markers: false,
        scrub:true
      }
    });
    return () => {  
      ScrollTrigger.getById('3409').kill();
    };
  }, 
  []);

  useLayoutEffect(() => {   

    tl_presentation.current = gsap.timeline()
    .from((presentation.current), {
      scrollTrigger: {
        id:'3410',
        trigger: presentation.current,
        markers: false,
        start: "top bottom",
        end: "top center",
        scrub: true,
      },
      x: "-=100%",
      scale: 0.5,
      opacity: 0
    })
    .from((presentation.current), {
      scrollTrigger: {
        id:'3410',
        trigger: presentation.current,
        markers: false,
        start: "top 350",
        end: "+=600 center",
        scrub: true,
        pin: presentation.current,
      }
    });
    return () => {  
      ScrollTrigger.getById('3410').kill();
    };
  }, []);

  useLayoutEffect(() => {   

    tl_images.current = gsap.timeline()
    .from((blackboard.current), {
      scrollTrigger: {
        id:'3411',
        trigger: blackboard.current,
        markers: false,
        start: "top center",
        end: "+=200 center",
        scrub: true,
      },
      x: "-=200",
      opacity: 0
    })
    .from((fists.current), {
      scrollTrigger: {
        id:'3411',
        trigger: fists.current,
        markers: false,
        start: "top center",
        end: "+=200 center",
        scrub: true,
      },
      x: "+=200",
      opacity: 0
    })
    .from((sunshine.current), {
      scrollTrigger: {
        id:'3411',
        trigger: sunshine.current,
        markers: false,
        start: "top center",
        end: "+=200 center",
        scrub: true,
      },
      x: "-=200",
      opacity: 0
    });
    return () => {  
      ScrollTrigger.getById('3411').kill();
    };
  
  }, []);

  
  return (
  <>
    <div className="yasyt__features-userx">
      <div className="yasyt__features-userx__title" >
          <h1 ref={userx}>UX</h1>
      </div>
    </div>
    <div className='yasyt__features-userx__UX-presentation'>
            <p ref={presentation}>{t("center")}</p>
    </div>
    <div className="yasyt__features-userx__UX">
    <div className="yasyt__features-userx__UX1">
        <div className='yasyt__features-userx__UX-img'>
          <img src={Blackboard} alt="mano pulsando boton en una alexa" ref={blackboard}/>
        </div>
        <div className='yasyt__features-userx__UX-img'>
          <img src={Sunshine} alt="Se ve al robot Pepper saludando a una niña" ref={sunshine}/>
        </div>
      </div>
      <div className="yasyt__features-userx__UX2">
        <div className='yasyt__features-userx__UX-text'>
            <p>{t("products_1")}</p><br />
            <p>{t("products_2")}</p><br />
            <p>{t("products_3")}</p><br />
            <p>{t("products_4")}</p>
        </div>
        <div className='yasyt__features-userx__UX-img'>
          <img src={Fists} alt="se ve al robot Pepper mirando a camara" ref={fists}/>
        </div>
      </div>
    </div>
  </>);
}

export default Feature7
