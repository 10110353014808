import en from "../../data/trans/en.json";
import es from "../../data/trans/es.json";
import ca from "../../data/trans/ca.json";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";

const resources = {
    en:{
        translation: en,
    },
    es: {
        translation: es,
    },
    ca: {
        translation: ca,
    }
};

i18n
.use(initReactI18next)
.init({
    resources,
    lng: JSON.parse(localStorage.getItem("language")),
    fallbackLng: "en",

});

export default i18n;