import React from 'react';
import './timelineitem.css';

const TimelineItem = ({data}) => (

    <div className="yasyt__timeline-item">
        <div className="yasyt__timeline-item__content" style={{backgroundColor: data.category.color}}>
            <div className="yasyt__timeline-item__header">
                <span className="yasyt__timeline-item__tag">
                    {data.category.tag}
                </span>
                <time>{data.date}</time>
            </div>
            <div className="yasyt__timeline-item__body">
                <p className="yasyt__timeline-item__text" >{data.text}</p>
                {data.photo && (
                    <div className="yasyt__timeline-item__body-photo">
                        <img className="yasyt__timeline-item__image" src={data.photo.url} alt={data.photo.alt}/>
                        <p><b>{data.name}</b><br/>{data.charge}</p>
                    </div>
                )}
            </div>
{/*             {data.link && (
                <a
                    href={data.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {data.link.text}
                </a>
            )} */}
            <span className="yasyt__timeline-item__line" style={{borderColor: data.category.color}} />
            <span className="yasyt__timeline-item__circle1" style={{borderColor: data.category.color}} />
            <span className="yasyt__timeline-item__circle" style={{borderColor: data.category.color, backgroundColor: data.category.color}}/>
            <span className="yasyt__timeline-item__circle2" style={{borderColor: data.category.color}} />
            
        </div>
    </div>
);

export default TimelineItem;
