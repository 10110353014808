import React from 'react';
import './feature4.css';
import Alexa from '../../assets/home/social-04-web.jpg';
import Pepper from '../../assets/home/social-06-web.jpg';
import Peppergirl from '../../assets/home/social-07-web.jpg';
import { useTranslation } from 'react-i18next';

const Feature4 = () => {

  const {t} = useTranslation();


  return (

    <div className="yasyt__features-social__specs">
      <div className="yasyt__features-social__PAAS">
        <div className='yasyt__features-social__PAAS-img'>
          <img src={Alexa} alt="mano pulsando boton en una alexa"/>
        </div>
        <div className='yasyt__features-social__PAAS-text'>
          <p >{t("cicle")}</p>
        </div>
      </div>
      <div className="yasyt__features-social__PAAS">
      <div className='yasyt__features-social__PAAS-text'>
          <p >{t("need")}</p>
        </div>
        <div className='yasyt__features-social__PAAS-img'>
          <img src={Pepper} alt="se ve al robot Pepper mirando a camara"/>
        </div>

      </div>
      <div className="yasyt__features-social__PAAS">
        <div className='yasyt__features-social__PAAS-img japangirl'>
          <img src={Peppergirl} alt="Se ve al robot Pepper saludando a una niña"/>
        </div>
        <div className='yasyt__features-social__PAAS-text'>
          <p >{t("feedback")}</p>
        </div>
      </div>
    </div>
  )
};

export default Feature4
