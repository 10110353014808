import React, { useRef, useLayoutEffect } from 'react';
import './feature6.css';
import Office from '../../assets/home/equipo-01-web.jpg';
import Office2 from '../../assets/home/equipo-02-web.jpg';
import { gsap } from "gsap";
import{ ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';

function Feature6() {
  gsap.registerPlugin(ScrollTrigger);
  const office1 = useRef();
  const office2 = useRef();
  const office2text = useRef();
  const teamTitle = useRef();
  const teampresentation = useRef();
  const {t} = useTranslation();
  let tl_office1 = useRef();
  let tl_office2 = useRef();
  let tl_office2text = useRef();

  useLayoutEffect(() => {   

  tl_office1.current = gsap.timeline()
  .from((office1.current), {
    scrollTrigger: {
      id:'3412',
      trigger: office1.current,
      markers: false,
      start: "top bottom",
      end: "top center",
      scrub: true,
    },
    x: "-=100%",
    scale: 0.5,
  })
  .from((office1.current), {
    scrollTrigger: {
      id:'3412',
      trigger: office1.current,
      markers: false,
      start: "top 350",
      end: "+=600 center",
      scrub: true,
      pin: office1.current,
    }
  });
  return () => {  
    ScrollTrigger.getById('3412').kill();
  };
}, []);

useLayoutEffect(() => { 
  tl_office2.current = gsap.timeline()
  .from((office2.current), {
    scrollTrigger: {
      id:'3413',
      trigger: office2.current,
      markers: false,
      start: "45% center",
      end: "+=500 center",
      scrub: true,
      pin: office2.current,
    }
  })
  .to((office2.current), {
    scrollTrigger: {
      id:'3413',
      trigger: office2.current,
      markers: false,
      start: "top top",
      end: "+=400 top",
      scrub: true,
    }, 
    opacity: 0,
    scale: 0.5,
    duration: 10
  });
  return () => {  
    ScrollTrigger.getById('3413').kill();
  };
  }, []);

  useLayoutEffect(() => { 
    tl_office2text.current = gsap.timeline()
    .from((office2text.current), {
      scrollTrigger: {
        id:'3414',
        trigger: office2text.current,
        markers: false,
        start: "45% center",
        end: "+=1000 center",
        scrub: true,
        pin: office2text.current,
      }
    })
    .to((office2text.current), {
      scrollTrigger: {
        id:'3414',
        trigger: office2text.current,
        markers: false,
        start: "top top",
        end: "+=600 top",
        scrub: true,
      }, 
      opacity: 0,
      scale: 0.2,
      duration: 3
    });
    return () => {  
      ScrollTrigger.getById('3414').kill();
    };
    }, []);

  useLayoutEffect(() => {
    gsap.from(teamTitle.current, {
      scale: 10,
      duration: 10,
      delay: 0,
      ease: "elastic.out(1, 0.3)",
      scrollTrigger: {
        id:'3415',
        trigger: ".yasyt__features-team__title",
        start:"top bottom",
        end: "+=200 top",
        markers: false,
        scrub:true
      }
    });
    return () => {  
      ScrollTrigger.getById('3415').kill();
    };
  }, 
  []);
  
  return (
  <>
    <div className="yasyt__features-team__yasyt">
      <div className="yasyt__features-team__title" >
        <h1 ref={teamTitle}>{t("team")}</h1>
      </div>
      <div className="yasyt__features-team__office1" >
        <img src={Office} alt="Assistant" ref={office1}/>
      </div>
      <div className="yasyt__features-team__office2" > 
        <p ref={office2text}>{t("professional")}</p>
        <img src={Office2} alt="hospital" ref={office2}/>
      </div>
      <div className='yasyt_features-team__textdescription' ref={teampresentation}>
        <p>{t("heritage_1")}</p><br />
        <p>{t("heritage_2")}</p><br />
        <p>{t("heritage_3")}</p><br />
        <p>{t("heritage_4")}</p>
      </div>
    </div>
  </>);
}

export default Feature6
