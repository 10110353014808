import React from 'react';
import './politica.css';
import Footer from "../../containers/footer/Footer";

const Politica = () => {

    return (
        <div class="yasyt__politica-main">
            <div class="yasyt__politica-text ">
                <h3 id="responsable">Responsable. ¿Quién es el Responsable del tratamiento de sus datos?</h3>
                <p>Le informamos que YASYT ROBOTICS, S.L. (en adelante “Yasyt”) es la titular de la web yasyt.com, con CIF B67269639 y domicilio en la Pº. Bonanova, num. 94, bis, piso 1º 2ª, 08017, Barcelona. Puede contactar por correo electrónico a <span><u><a href="mailto: hello@yasyt.com"> hello@yasyt.com</a></u></span><span>.</span></p>
                <h3>Legitimación. ¿Cuál es la legitimación para el tratamiento de sus datos?</h3>
                <p>La base legal para el tratamiento de sus datos está basada en el consentimiento que se le solicitará en los diferentes formularios de contacto. El usuario que facilite datos personales través de los diferentes formularios de la web de “Yasyt”, está proporcionando voluntariamente datos de carácter personal (de ahora en adelante los Datos Personales). La información personal que nos facilite pasará a ser introducida en un tratamiento bajo la responsabilidad de “Yasyt”, y se mantendrá confidencial y protegida.</p>
                <p>En tal sentido, “Yasyt” informa a los usuarios que, en cumplimiento del RGPD 679/2016 y la L.O. 3/2018 dispone de un Registro de Actividades. Por todo ello, el usuario acepta de forma expresa, plenamente y sin reservas que “Yasyt” trate sus datos personales de forma automatizada e incorpore en el tratamiento de datos con la finalidad de poderle responder las consultas que usted plantee, y prestar y ofrecer sus servicios. En el epígrafe finalidad le detallamos ampliamente con qué finalidad “Yasyt” tratará sus datos personales.</p>
                <p>Esta política de privacidad describe la recopilación y el uso que hacemos de su información personal. Salvo que específicamente se establezca lo contrario, se considerará necesario completar todos los datos obligatorios para utilizar todos los formularios.</p>
                <h3>Procedencia. ¿Cómo hemos obtenido sus datos?</h3>
                <p>Toda la información personal, datos de carácter personal, que tenemos de usted nos la ha facilitado a través de los diferentes formularios o en los correos electrónicos.</p>
                <p>Datos que se solicitan en el formulario de contacto:</p>
                <ul>
                    <li>Nombre y apellidos.</li>
                    <li>Correo electrónico.</li>
                </ul>
                <p>Visitar la web de “Yasyt” no supone que el usuario esté obligado a facilitar ninguna información sobre sí mismo.</p>
                <p>El usuario tendrá la opción de prestar su aceptación y consentimiento al tratamiento de sus Datos Personales por parte de “Yasyt” de conformidad con la presente Política de Protección de Datos.</p>
                <p>“Yasyt” prohíbe expresamente que los menores de dieciocho años faciliten Datos Personales sin el consentimiento previo de sus padres o tutores. En caso de ser menor de dieciocho años y no tener dicho consentimiento, no facilite Datos Personales a “Yasyt” ni utilice nuestros servicios.</p>
                <p>El usuario deberá facilitar a “Yasyt” los Datos Personales que se le puedan requerir cuando utilice el formulario de contacto. Los Datos Personales serán tratados con el grado de protección legalmente exigible para garantizar la seguridad de los mismos y evitar su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, siendo el usuario consciente y aceptando que las medidas de seguridad en Internet no son inexpugnables.</p>
                <h3 id="finalidad">Finalidad. ¿Con qué finalidad tratamos sus datos personales?</h3>
                <p>En “Yasyt” tratamos la información que nos facilita para gestionar las consultas recibidas.</p>
                <p>En resumen, de lo dicho hasta el momento, la información personal que nos facilite a través de los diferentes formularios y en los correos electrónicos, se mantendrá confidencial y protegida. En ningún caso se destinará o utilizará para otros fines, ni se entregará a terceras personas sin su consentimiento, de acuerdo con los principios del RGPD 679/2016 y la L.O. 3/2018. Para cumplir con las distintas disposiciones legales, hemos establecido diferentes niveles de seguridad para proteger los datos personales que nos faciliten, habiendo adoptado todas las medidas técnicas a nuestro alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y manipulación de dichos datos personales.</p>
                <p>Nuestro compromiso:</p>
                <ul>
                    <li>Prometemos proteger su intimidad y tratar de forma confidencial la información que proporcione.</li>
                    <li>La información que proporcione será utilizada únicamente para responder a sus consultar o referente a los servicios contratados.</li>
                    <li>No revelaremos su información personal a terceros sin su consentimiento.</li>
                </ul>
                <h3>¿Por cuánto tiempo conservaremos sus datos?</h3>
                <p>Los datos de carácter personal se conservarán en cumplimiento de los plazos legales de prescripción que resulten de aplicación.</p>
                <h3>Destinatarios. ¿A qué destinatarios se comunicarán sus datos?</h3>
                <p>Estos datos serán tratados por “Yasyt” y serán sometidos a secreto profesional para poder prestarle el servicio solicitado.</p>
                <h3>Derechos. ¿Cuáles son sus derechos cuando nos facilita sus datos?</h3>
                <p>Cualquier persona tiene derecho a obtener confirmación sobre si en “Yasyt” estamos tratando datos personales que les conciernan, o no. Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión. Tiene siempre derecho a ejercer sus derechos de información, acceso, rectificación, oposición, supresión, limitación de tratamiento y portabilidad de los datos personales que nos haya proporcionado, establecidos en la RGPD 679/2016 y la L.O. 3/2018. Puede hacerlo por escrito a su domicilio social sito en Pº. Bonanova, num. 94, bis, piso 1º 2ª, 08017, Barcelona. Podrá dirigirse a la Autoridad de Control competente para presentar la reclamación que considere oportuna.</p>
                <h3>Política de Cookies</h3>
                <p>Las cookies son pequeños archivos que se instalan en el ordenador del usuario y que permiten al responsable de un servicio mejorar la forma de interactuar entre sus visitantes y su sitio web. En algunos casos, la instalación de estas cookies requiere del consentimiento del usuario.</p>
                <p>Existen diferentes clases de cookies, afectadas y no afectadas por la Ley:</p>
                <ul>
                    <li>Cookies no afectadas:
                        <ul className='indent'>
                            <li>Técnicas: Son necesaria para la navegación. Sin ellas la página no funcionaría adecuadamente.</li>
                            <li>De personalización o configuración: Permiten que la página reconozca el idioma del usuario, el tamaño de letras, etc.</li>
                            <li>De seguridad: Impiden o dificultan ataques contra el sitio web o sus usuarios.</li>
                        </ul>
                    </li>
                    <li>Cookies afectadas:
                        <ul className='indent'>
                            <li>Analíticas: Permiten medir la actividad de los usuarios y elaborar estadísticas de navegación.</li>
                            <li>Publicitarias: Gestionan la frecuencia y el contenido de los anuncios.</li>
                            <li>De seguimiento: Almacenan información sobre los usuarios para mostrarles publicidad personalizada.</li>
                        </ul>
                    </li>
                </ul>
                <p>“Yasyt” informa que el sitio web utiliza Google Analytics, un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos ("Google").</p>
                <p>Google Analytics utiliza "cookies", que son archivos de texto ubicados en su ordenador, para ayudar al website a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso del website (incluyendo su dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos. Lista <span><u><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es-419">cookies de Google Analytics</a></u></span></p>
                <p>Cuando visita la web de “Yasyt”, algunas cookies no las instalaremos nosotros, sino que las instalarán terceros. Por ejemplo, si hace clic en un anuncio de un tercero que aparece en el sitio web (es decir, que anuncia un producto que no vendemos nosotros), ese tercero puede instalar las cookies en su dispositivo. Tenemos una relación comercial con estos terceros pero no instalamos las cookies en su dispositivo. Por tanto, sugerimos que vaya a los sitios web de estos terceros para obtener más información sobre las cookies que instalan y cómo usted puede gestionarlas.</p>
                <p>Puede usted rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada de su navegador, sin embargo, debe usted saber que si lo hace puede ser que no pueda usar la plena funcionabilidad de este website. Al utilizar este sitio Web usted consiente el tratamiento de información acerca de usted por Google en la forma y para los fines arriba indicados.</p>
                <p>Para desactivar/activar cookies de su dispositivo, tendrá que hacerlo a través de su navegador de Internet.</p>
                <h3>¿Cómo desactivar/activar las cookies de terceros?</h3>
                <p>Las cookies de terceros no las instalamos nosotros. Por lo tanto, sugerimos que mire en los sitios web de esos terceros para obtener información sobre las cookies que instalan y cómo las puede gestionar.</p>
            </div>
            <Footer />
        </div>
    );
};

export default Politica;


