import React from 'react';
import { FullpageAccordion, Panel } from "react-fullpage-accordion";
import "react-fullpage-accordion/dist/react-fullpage-accordion.css";
import metodo01 from "../../assets/metodo/IMG-Metodo-01.jpg";
import metodo02 from "../../assets/metodo/IMG-Metodo-02.jpg";
import metodo03 from "../../assets/metodo/IMG-Metodo-03.jpg";
import metodo04 from "../../assets/metodo/IMG-Metodo-04.jpg";
import metodo05 from "../../assets/metodo/IMG-Metodo-05.jpg";
import Footer from "../footer/Footer";
import './method.css';
import { Trans, useTranslation } from 'react-i18next';

const Method = () => {
  const {t} = useTranslation(); 
  
  return (
    <>
      <div className="yasyt__method">
        <FullpageAccordion>
          <Panel
            itemId="0"
            background={metodo01}
          >
            <div className='yasyt__method-container'>
              <h1 className='yasyt__method-title'>{t("who")}</h1>
              <p className='yasyt__method-number' >01</p>
              <h6 className='yasyt__method-description' >
                <div>
                <Trans>
              {t("who_description")}
              </Trans>
              </div>
              </h6>
            </div>
          </Panel>
          <Panel
            itemId="1"
            background={metodo02}
          >
            <div className='yasyt__method-container'>
              <h1 className='yasyt__method-title'>{t("what")}</h1>
              <p className='yasyt__method-number' >02</p>
              <h6 className='yasyt__method-description' >
                <div>
                  <Trans>{t("what_description")}</Trans>
                </div>
              </h6>
            </div>
          </Panel>
          <Panel
            itemId="2"
            background={metodo03}
          >
            <div className='yasyt__method-container'>
              <h1 className='yasyt__method-title'>{t("when")}</h1>
              <p className='yasyt__method-number' >03</p>
              <h6 className='yasyt__method-description' >
                <div>
                  <Trans>{t("when_description")}</Trans>
                </div>
              </h6>
            </div>
          </Panel>
          <Panel
            itemId="3"
            background={metodo04}
          >
            <div className='yasyt__method-container'>
              <h1 className='yasyt__method-title'>{t("where")}</h1>
              <p className='yasyt__method-number' >04</p>
              <h6 className='yasyt__method-description'>
                <div>
                  <Trans>{t("where_description")}</Trans>
                </div>
              </h6>
            </div>
          </Panel>
          <Panel
            itemId="4"
            background={metodo05}
          >
            <div className='yasyt__method-container'>
              <h1 className='yasyt__method-title'>{t("why")}</h1>
              <p className='yasyt__method-number' >05</p>
              <h6 className='yasyt__method-description' >
                <div>
                  <Trans>{t("why_description")}</Trans>
                </div>
              </h6>
            </div>
          </Panel>
        </FullpageAccordion>
      </div>
      <Footer />
    </>
  );
};

export default Method;