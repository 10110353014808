import React from 'react';
import './partner.css';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Brand = ({partner}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className='yasyt__brand-card' sx={{bgcolor: '#202020'}} >
      
      <CardMedia
        component="img"
        height="100%"
        src={partner.url}
        alt="Logo"
      />

      <CardActions disableSpacing>

        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          
        >
          <ExpandMoreIcon  sx={{color: '#fff'}}/>
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} orientation="vertical" timeout="auto" unmountOnExit>
        <CardContent className='yasyt__brand-typography'>
          <Typography paragraph variant="h4">{partner.title}</Typography>
          <Typography paragraph>
            {partner.details}
          </Typography>
          
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default Brand;
