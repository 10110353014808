import React from 'react';
import './terminos.css';
import Footer from "../../containers/footer/Footer";

const Terminos = () => {

    return (
        <div className='yasyt__terminos-main'>
            <div class="yasyt__terminos-text">
                <p>Doy mi consentimiento para que la entidad envíe la información que pueda ser de mi interés a la dirección de correo electrónico facilitado.</p>

                            <p>YASYT, con dirección en Paseo Bonanova 94 bis, teléfono 932057611, como responsable del tratamiento,
                                y en aplicación del Reglamento General de Protección, le informa que los datos facilitados serán
                                utilizados únicamente para el fin de mantenerlo informado de cuestiones que le pueden ser de interés,
                                así como para establecer contacto con usted, siendo la base jurídica del tratamiento su propio consentimiento.</p>
                            <p>Los datos únicamente serán utilizados para YASYT, dentro de sus competencias y funciones, y serán conservadas
                                durante el tiempo necesario para cumplir con la finalidad descrita o bien hasta que usted retire su consentimiento.</p>
                            <p>En ningún caso serán comunicados a terceros ni serán transferidos a terceros países. </p>
                            <p>Puede acceder a sus datos, solicitar la rectificación o supresión, oponerse al tratamiento y solicitar la limitación,
                                enviando su solicitud por escrito a la dirección del responsable del tratamiento adjuntando una fotocopia de su DNI o
                                documento acreditativo de su identidad, o bien enviando un correo electrónico a la siguiente dirección 
                            <a href="mailto:ninfiesta@yasyt.com">
                                <span> ninfiesta@yasyt.com </span>
                            </a>
                            en caso de que, en algún momento, considere que se han vulnerado sus derechos puede presentar una reclamación dirigida a la APDCAT, 
                            mediante la sede electrónica de la Autoridad (https://seu.apd.cat) o por medios no electrónicos.</p>
        </div >
    <Footer />
        </div >
    );
};

export default Terminos;

