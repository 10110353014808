import React from 'react';
import { Link } from 'react-router-dom';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from 'gsap';

import guidyt01 from '../../assets/projects/guidyt/guidyt-04.jpg';
import guidyt02 from '../../assets/projects/guidyt/guidyt-05.jpg';
import guidyt03 from '../../assets/projects/guidyt/guidyt-06.jpg';
import guidyt04 from '../../assets/projects/guidyt/guidyt-07.jpg';
import guidyt05 from '../../assets/projects/guidyt/guidyt-08.jpg';
import guidyt06 from '../../assets/projects/guidyt/guidyt-09.jpg';
import Footer from '../../containers/footer/Footer';
import { Trans, useTranslation } from 'react-i18next';

import './project1.css';

const { useLayoutEffect, useEffect, useRef, useState, forwardRef } = React;

const FadeIn = forwardRef(({ children, stagger = 0, x = 0, y = 0 }, ref) => {
  const el = useRef();
  const animation = useRef();
  

  useLayoutEffect(() => {
    animation.current = gsap.from(el.current.children, {
      opacity: 0,
      stagger,
      x,
      y
    });
  });


  useEffect(() => {

    // forward the animation instance
    if (typeof ref === "function") {
      ref(animation.current);
    } else if (ref) {
      ref.current = animation.current;
    }
  }, [ref]);

  return <span ref={el}>{children}</span>
});

const SlideProject1 = () => {

  gsap.registerPlugin(ScrollTrigger);

  const animation1 = useRef();
  const panels = useRef([]);
  const panelsContainer = useRef();
  const description = useRef();
  const {t} = useTranslation();

  const createPanelsRefs = (panel, index) => {
    panels.current[index] = panel;
  };

  useLayoutEffect(() => {
    const totalPanels = panels.current.length;

    gsap.to(panels.current, {
      xPercent: -100 * (totalPanels - 1),
      ease: "none",
      scrollTrigger: {
        trigger: panelsContainer.current,
        id: '3436',
        pin: true,
        scrub: 1,
        snap: 1 / (totalPanels - 1),
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + panelsContainer.current.offsetWidth
      }
    });
    return () => {
      ScrollTrigger.getById('3436').kill();
    };
  }, []);

  useLayoutEffect(() => {

    gsap.to(description.current, {
      yPercent: -15,
      ease: "none",
      scrollTrigger: {
        trigger: description.current,
        id: '3437',

        scrub: 1,
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + panelsContainer.current.offsetWidth
      }
    });
    return () => {
      ScrollTrigger.getById('3437').kill();
    };
  }, []);

  return (
    <>
      <div className="yasyt__project-guidyt__slide" ref={panelsContainer}>

        <Link to='/projects'>
          <h3 className='yasyt__project-guidyt__back'>Back</h3>
        </Link>
        <div className='yasyt__project-guidyt__text-inside' >
          <FadeIn stagger={0.3} x={-100} ref={animation1}>
            <div ref={description}>
            <Trans>{t("project_2_description")}</Trans>
            </div>
          </FadeIn>
        </div>
        <div className="yasyt__projects-guidyt__images" ref={(e) => createPanelsRefs(e, 0)}>
          <img src={guidyt02} alt="Imagen de un hombre de una residencia" />
        </div>
        <div className="yasyt__projects-guidyt__images" ref={(e) => createPanelsRefs(e, 1)}>
          <img src={guidyt03} alt="Imagen de un hombre de una residencia" />
        </div>
        <div className="yasyt__projects-guidyt__images" ref={(e) => createPanelsRefs(e, 2)}>
          <img src={guidyt04} alt="Imagen de un hombre de una residencia" />
        </div>
        <div className="yasyt__projects-guidyt__images" ref={(e) => createPanelsRefs(e, 3)}>
          <img src={guidyt05} alt="Imagen de un hombre de una residencia" />
        </div>
        <div className="yasyt__projects-guidyt__images" ref={(e) => createPanelsRefs(e, 4)}>
          <img src={guidyt06} alt="Imagen de un hombre de una residencia" />
        </div>
      </div>
      <Footer />
    </>
  );
}


const Project1 = () => {

  const animation0 = useRef();
  const animation1 = useRef();
  const animation2 = useRef();
  const {t} = useTranslation();
  const [toggleMore, setToggleMore] = useState(true);

  return (
    <>
      {toggleMore ? (
        <>
          <div className="yasyt__project-guidyt__inside">
            <div>
              <FadeIn stagger={0.5} x={200} ref={animation2}>
                <h1 className="yasyt__project-guidyt__title-inside" >Guidyt</h1>
              </FadeIn>
              <FadeIn stagger={0.1} y={100} ref={animation0}>
                <h3 onClick={() => setToggleMore(false)} className='yasyt__project-guidyt__more'>More</h3>
              </FadeIn>
              <Link to='/projects'>
                <h3 className='yasyt__project-guidyt__back'>Back</h3>
              </Link>

              <div className='yasyt__project-guidyt__text'>
                <FadeIn stagger={0.3} x={-100} ref={animation1}>
                  <Trans>{t("project_2_title")}</Trans>
                </FadeIn>
              </div>
              <img src={guidyt01} alt="Imagen de un hombre de una residencia" />
            </div>
          </div>
          <Footer />
        </>
      )
        : <SlideProject1 />}
    </>
  );
}

export default Project1;