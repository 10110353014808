import React from 'react';
import { Partner } from '../../components';
import './partners.css';
import partners_es from '../../data/db_es.json';
import partners_en from '../../data/db_en.json';
import partners_ca from '../../data/db_ca.json';
import Footer from "../footer/Footer";
import { Trans, useTranslation } from 'react-i18next';
/* import { useEffect } from "react"; */

/* function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
} */

const Clients = () => {

  const {t} = useTranslation();
  let partneres = t(['partner_data_language']);

  if(partneres === "CA") {
     partneres = partners_ca; 
  } else if (partneres === "ES") {
    partneres = partners_es;
  } else {
    partneres = partners_en;
  }

  return (
    <>
    {/* <ScrollToTopOnMount /> */}
      <div className='yasyt__clients'>
        <div className='yasyt__clients-intro'>
          <Trans>{t("partner_description")}</Trans>
        </div>
        <div className="yasyt__clients-container">
          {partneres.map((data, key) => {
            return <Partner key={key} partner={data} />
          })}
        </div>
      </div>
      <Footer />
    </>
  )
};

export default Clients;
