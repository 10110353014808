import React from 'react';
import './company.css';
import timelineData_es from '../../data/companydb_es.json';
import timelineData_en from '../../data/companydb_en.json';
import timelineData_ca from '../../data/companydb_ca.json';
import { TimelineItem } from '../../components';
import Footer from '../../containers/footer/Footer';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

const Company = () => {

    const {t} = useTranslation();
    let timelineData = t(['partner_data_language']);
  
    if(timelineData === "CA") {
        timelineData = timelineData_ca; 
    } else if (timelineData === "ES") {
        timelineData = timelineData_es;
    } else {
        timelineData = timelineData_en;
    }

    return (
        timelineData.length > 0 && (
            <>
            <ScrollToTopOnMount />
                <div className="yasyt__company-spacer" />
                <span className="yasyt__company-item__circle" />
                <div className="yasyt__company-container">
                    <div className="yasyt__company-spacer" />
                    {timelineData.map((data, idx) => (
                        <TimelineItem data={data} key={idx} />
                    ))}
                    <div className="yasyt__company-spacer" />
                    <span className="yasyt__company-item__circleend" />
                </div>

                <div className="yasyt__company-spacer">

                </div>
                <Footer />
            </>
        )
    )
};

export default Company;
