import React from 'react';
import { Link } from 'react-router-dom';

import{ ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from 'gsap';
import mynewfriend01 from '../../assets/projects/mynewfriend/mynewfriend-01.jpg';
import mynewfriend02 from '../../assets/projects/mynewfriend/mynewfriend-02.jpg';
import mynewfriend03 from '../../assets/projects/mynewfriend/mynewfriend-03.jpg';
import mynewfriend04 from '../../assets/projects/mynewfriend/mynewfriend-04.jpg';
import mynewfriend05 from '../../assets/projects/mynewfriend/mynewfriend-05.jpg';
import Footer from '../../containers/footer/Footer';
import { Trans, useTranslation } from 'react-i18next';

import './project5.css';

const { useLayoutEffect, useEffect, useRef, useState, forwardRef } = React;

const FadeIn = forwardRef(({ children, stagger = 0, x = 0, y=0 }, ref) => {
  const el = useRef();
  const animation = useRef();
  
  useLayoutEffect(() => {    
    animation.current = gsap.from(el.current.children, { 
      opacity: 0,
      stagger, 
      x,
      y
    });        
  });

  
  useEffect(() => {
    
    // forward the animation instance
    if (typeof ref === "function") {
      ref(animation.current);
    } else if (ref) {
      ref.current = animation.current;
    }
  }, [ref]);
  
  return <span ref={el}>{children}</span>
});

const SlideProject5 = ()=> {

  gsap.registerPlugin(ScrollTrigger);

  const animation1 = useRef();
  const panels = useRef([]);
  const panelsContainer = useRef();
  const description = useRef();
  const {t} = useTranslation();

  const createPanelsRefs = (panel, index) => {
    panels.current[index] = panel;
  };

  useLayoutEffect(() => {
    const totalPanels = panels.current.length;

    gsap.to(panels.current, {
      xPercent: -100 * (totalPanels - 1),
      ease: "none",
      scrollTrigger: {
        trigger: panelsContainer.current,
        id:'3444',
        pin: true,
        scrub: 1,
        snap: 1 / (totalPanels - 1),
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + panelsContainer.current.offsetWidth
      }
    });
    return () => {  
      ScrollTrigger.getById('3444').kill();
    };
  }, []);

  useLayoutEffect(() => {

    gsap.to(description.current, {
      yPercent: -15,
      ease: "none",
      scrollTrigger: {
        trigger: description.current,
        id:'3445',
        
        scrub: 1,
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + panelsContainer.current.offsetWidth
      }
    });
    return () => {  
      ScrollTrigger.getById('3445').kill();
    };
  }, []);

  return (
    <>
    <div className="yasyt__project-mynewfriend__slide" ref={panelsContainer}>
      
        <Link to='/projects'>
          <h3 className='yasyt__project-mynewfriend__back'>Back</h3>
        </Link>
        <div className='yasyt__project-mynewfriend__text-inside' >  
          <FadeIn stagger={0.3} x={-100} ref={animation1}>
            <div ref={description}>
              <Trans>{t("project_6_description")}</Trans>
            </div>
          </FadeIn>
        </div>
        <div className="yasyt__projects-mynewfriend__images" ref={(e) => createPanelsRefs(e, 0)}>  
          <img src={mynewfriend01} alt="Imagen de un hombre de una residencia"/>
        </div>
        <div className="yasyt__projects-mynewfriend__images" ref={(e) => createPanelsRefs(e, 1)}>   
          <img src={mynewfriend02} alt="Imagen de un hombre de una residencia"/>
        </div>
        <div className="yasyt__projects-mynewfriend__images" ref={(e) => createPanelsRefs(e, 2)}>   
          <img src={mynewfriend03} alt="Imagen de un hombre de una residencia"/>
        </div>
        <div className="yasyt__projects-mynewfriend__images" ref={(e) => createPanelsRefs(e, 3)}>   
          <img src={mynewfriend04} alt="Imagen de un hombre de una residencia"/>
        </div>
        <div className="yasyt__projects-mynewfriend__images" ref={(e) => createPanelsRefs(e, 4)}>   
          <img src={mynewfriend05} alt="Imagen de un hombre de una residencia"/>
        </div>
    </div>
    <Footer />
    </>
  );
 }


const Project5 = () => {

  const animation0 = useRef();
  const animation1 = useRef();
  const animation2 = useRef();
  const {t} = useTranslation();

  const [toggleMore, setToggleMore] = useState(true);

  return (
    <>
    {toggleMore ? (
      <>
      <div className="yasyt__project-mynewfriend__inside">
        <div>
          <FadeIn stagger={0.5} x={200} ref={animation2}>
            <h1 className="yasyt__project-mynewfriend__title-inside" >mynewfriend</h1>
          </FadeIn>
          <FadeIn stagger={0.1} y={100} ref={animation0}>
            <h3 onClick={()=>setToggleMore(false)} className='yasyt__project-mynewfriend__more'>More</h3>
          </FadeIn>
          <Link to='/projects'>
            <h3 className='yasyt__project-mynewfriend__back'>Back</h3>
          </Link>
          
          <div className='yasyt__project-mynewfriend__text'>  
            <FadeIn stagger={0.3} x={-100} ref={animation1}>
              <Trans>{t("project_6_title")}</Trans>
            </FadeIn>
          </div>
          <img src={mynewfriend01} alt="Imagen de un hombre de una residencia"/>
        </div>
      </div>
          <Footer />
          </>
    ) 
    : <SlideProject5 />} 
</>
  );
}

export default Project5;