import React from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
  } from "react-router-dom";

import { Header, Footer, Projects, Partners, Method, Company } from './containers'; 
import { Navbar, Feature7, Feature6, Feature5, Feature4, Feature3, Feature2, Feature1, Feature0, Project0, Project1, Project2, Project3, Project4, Project5, Politica, Terminos, ErrorPage } from './components';
/* import { ScrollToTop } from './components'; */
import './App.css';



/* const App = () => {
    return (
        <>
            
                <BrowserRouter>
                    
                        <Navbar />
                        
                        <Routes>
                
                            <Route path='/' element={
                                <>
                                    <div className='gradient__bg'>
                                    <Header />
                                    <Feature0 />
                                    <Feature1 />
                                    <Feature2 />
                                    <Feature3 />
                                    <Feature4 />
                                    <Feature5 />
                                    <Feature6 />
                                    <Feature7 />
                                    <Footer />
                                    </div>
                                </>
                            }>
                            </Route>
                            <Route path='/projects' element={<Projects />}/>
                            <Route path='/projects/helderly' element={<Project0 />}/>
                            <Route path='/projects/guidyt' element={<Project1 />}/>
                            <Route path='/projects/close2u' element={<Project2 />}/>
                            <Route path='/projects/robroom' element={<Project3 />}/>
                            <Route path='/projects/robhome' element={<Project4 />}/>
                            <Route path='/projects/mynewfriend' element={<Project5 />}/>
                            <Route path='/partners' element={<Partners />}/>
                            <Route path='/method' element={<Method />}/>
                            <Route path='/company' element={<Company />}/>
                            <Route path='/politica' element={<Politica />}/>
                            <Route path='/terminos' element={<Terminos />}/>
                            <Route path='*' element={<ErrorPage />}/>
                        </Routes>     
                </BrowserRouter>
            
            
        </>
    )
}



export default App */


let router = createBrowserRouter(
    createRoutesFromElements(
        <>
        {/* <Navbar /> */}
        <Route path="/" errorElement={<ErrorPage />} element={
                                <>
                                <Navbar />
                                    <div className='gradient__bg'>
                                    <Header />
                                    <Feature0 />
                                    <Feature1 />
                                    <Feature2 />
                                    <Feature3 />
                                    <Feature4 />
                                    <Feature5 />
                                    <Feature6 />
                                    <Feature7 />
                                    <Footer />
                                    </div>
                                </>
                            }/>
                            
                            <Route path='/projects' element={<><Navbar /><Projects /></>}/>
                            <Route path='/projects/helderly' element={<><Navbar /><Project0 /></>}/>
                            <Route path='/projects/guidyt' element={<><Navbar /><Project1 /></>}/>
                            <Route path='/projects/close2u' element={<><Navbar /><Project2 /></>}/>
                            <Route path='/projects/robroom' element={<><Navbar /><Project3 /></>}/>
                            <Route path='/projects/robhome' element={<><Navbar /><Project4 /></>}/>
                            <Route path='/projects/mynewfriend' element={<><Navbar /><Project5 /></>}/>
                            <Route path='/partners' element={<><Navbar /><Partners /></>}/>
                            <Route path='/method' element={<><Navbar /><Method /></>}/>
                            <Route path='/company' element={<><Navbar /><Company /></>}/>
                            <Route path='/politica' element={<><Navbar /><Politica /></>}/>
                            <Route path='/terminos' element={<><Navbar /><Terminos /></>}/>
                            
        </>

      
    )
  );
  
  if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
  }
  
  export default function App() {
    return <RouterProvider router={router} fallbackElement={<ErrorPage />} errorElement={<ErrorPage />} />;
  }
