import React, { useRef, useLayoutEffect } from 'react';
import './feature1.css';
import { gsap } from "gsap";
import{ ScrollTrigger } from "gsap/ScrollTrigger";



function Feature1(){
  gsap.registerPlugin(ScrollTrigger);

  const ref2 = useRef(null);
  const frameCount2 = 22;
  const currentFrame2 = index => (
    `https://www.yasyt.com/home_images/pepper_web_02/VideoPepperWeb-02-${(index + 1).toString().padStart(4, '0')}.jpg`
  );

  const images2 = []
  const airpods2 = {
    frame: 0
  };

  for (let i = 0; i < frameCount2; i++) {
    const img2 = new Image();
    img2.src = currentFrame2(i);
    console.log(currentFrame2[i])
    images2.push(img2);
  }

  useLayoutEffect(() => {
    const element2 = ref2.current;
    const canvas2 = element2.querySelector(".box2");
    const context2 = canvas2.getContext("2d");

    canvas2.width = 1920;
    canvas2.height = 1080;

    gsap.to(airpods2, {
      frame: frameCount2 - 1,
      snap: "frame",
      ease: "none",
      scrollTrigger: {
        id: '3424',
        scrub: 0.1,
        start: "top top",
        end: "+=600",
        pin:".box2",
        markers:false
      }, onUpdate: render2  // use animation onUpdate instead of scrollTrigger's onUpdate
    })
    // Clean up before unmounting the component/page
    images2[0].onload = render2

    function render2() {
      context2.clearRect(0, 0, canvas2.width, canvas2.height);
      context2.drawImage(images2[airpods2.frame], 0, 0); 
    }

    return () => {  
      ScrollTrigger.getById('3424').kill();
    };

  });
  
  return(
  <div className="yasyt__features-social">
    <div className='yasytorie' ref={ref2} >
      <canvas className='box2'/>
    </div>
  </div>
  );
}

export default Feature1
