import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdMenu, MdClose } from 'react-icons/md';
import './navbar.css';
import logo from '../../assets/logo.png';
import i18n from "../utils/i18n";
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../../hooks/use-localstorage';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const Navbar = () => {

  const {t} = useTranslation();
  const [language, setLanguage] = useLocalStorage('language', 'en');

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value);
  };
  
  /* const [isOpen, setIsOpen] = useState(false); */

  const Menu = () => (
    <>
      <Link to='/method' className="yasyt__navbar-link btn btn-2" >{t('method')}</Link>
      <Link to='/projects' className="yasyt__navbar-link btn btn-2" >{t('projects')}</Link>
      <Link to='/' className="yasyt__navbar-link-title" ><img src={logo} alt="logo" /></Link>
      <Link to='/partners' className="yasyt__navbar-link btn btn-2" >{t('partners')}</Link>
      <Link to='/company' className="yasyt__navbar-link btn btn-2" >{t('company')}</Link>

        <Select 
        sx={{ color: '#ececec', fontFamily: 'var(--font-family)', fontWeight: '400', textTransform: 'uppercase', fontSize: 'clamp(10px, 5vw, 15px)', margin: 2 }}
          defaultValue='en'
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          onChange={handleChange}
        >
          <MenuItem value={'en'}>English</MenuItem>
          <MenuItem value={'es'}>Castellano</MenuItem>
          <MenuItem value={'ca'}>Català</MenuItem>
        </Select>

    </>
  );

  const MenuToggle = () => (
    <>
      <Link to='/method' className="yasyt__navbar-link" onClick={() => setToggleMenu(false)} >{t('method')}</Link>
      <Link to='/projects' className="yasyt__navbar-link" onClick={() => setToggleMenu(false)} >{t('projects')}</Link>
      <Link to='/' className="yasyt__navbar-link" onClick={() => setToggleMenu(false)} >{t('home')}</Link>
      <Link to='/partners' className="yasyt__navbar-link" onClick={() => setToggleMenu(false)} >{t('partners')}</Link>
      <Link to='/company' className="yasyt__navbar-link" onClick={() => setToggleMenu(false)} >{t('company')}</Link>
    </>
  );
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="yasyt__navbar">
      <a className="yasyt__navbar-link-logotitle" href="/"><img src={logo} alt="logo" /></a>


      <div className="yasyt__navbar-links">
        <div className="yasyt__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className="yasyt__navbar-menu">
        {toggleMenu
          ? <MdClose color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <MdMenu color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <>
          <div className="darkBG2" onClick={() => setToggleMenu(false)}/>
          <div className="yasyt__navbar-menu_container scale-up-center">
            <div className="yasyt__navbar-menu_container-links">
              <MenuToggle />
            </div>
          </div>
          </>
        )}
      </div>
    </div>
  )
};

export default Navbar;