import React, { useRef } from "react";
import { useState } from "react";
import "./cta.css";
import emailjs from "@emailjs/browser";
import { RiCloseLine } from "react-icons/ri";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CTA = ({ setIsOpen }) => {
  const form = useRef(null);
  const { t } = useTranslation();

  const [feedback, setFeedback] = useState(false);
  const [feedbackError, setFeedbackError] = useState(false);

  function handleFeedbackClick() {
    setFeedback(!feedback);
  }

  function handleFeedbackError() {
    setFeedbackError(!feedbackError);
  }

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleCloseFS = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback(false);
  };

  const handleCloseFE = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackError(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);

  function handleClicky(aux) {
    setLoading(aux);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    handleClicky(true);
    handleClick();
    emailjs
      .sendForm(
        "service_sin5q0b",
        "template_cop71ue",
        form.current,
        "1-6OC6pVFtsINh_ax"
      )
      .then(
        (result) => {
          handleFeedbackClick();
          handleClicky(false);
        },
        (error) => {
          handleClicky(false);
          handleFeedbackError();
          form.current.reset();
        }
      );
    form.current.reset();
  };

  return (
    <>
      <div>
        <div className="darkBG" onClick={() => setIsOpen(false)} />
        <div className="centered">
          <div className="modal">
            <form id="formail" ref={form} onSubmit={sendEmail}>
              <div className="closeBtnSpace">
                <button className="closeBtn" onClick={() => setIsOpen(false)}>
                  <RiCloseLine style={{ marginBottom: "-3px" }} />
                </button>
              </div>

              <TextField
                autoComplete="off"
                helperText={t('cta_name_help')}
                id="demo-helper-text-aligned"
                label={t('cta_name')}
                name="name"
                type="text"
                required
                focused
              />

              <TextField
                autoComplete="off"
                helperText={t('cta_email_help')}
                id="demo-helper-text-aligned"
                label={t('cta_email')}
                name="form_email"
                type="email"
                required
                focused
              />

              <TextField
                autoComplete="off"
                id="outlined-multiline-static"
                label={t('cta_message')}
                multiline
                rows={4}
                type="text"
                name="message"
                required
                focused
              />

              {/*             <Button ref={btn} type="submit" className="botony" variant="contained" endIcon={<SendIcon />}>
              Enviar
            </Button> */}
              <LoadingButton
                type="submit"
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                variant="contained"
                className="botony"
              >
                {t('cta_submit')}
              </LoadingButton>
            </form>
          </div>
        </div>
      </div>

      <Snackbar
        className={"infoAlert"}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
        {t('cta_alert_info')}
        </Alert>
      </Snackbar>

      <div>
        <Snackbar
          className={"successAlert"}
          open={feedback}
          autoHideDuration={6000}
          onClose={handleCloseFS}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseFS}
            severity="success"
            sx={{ width: "100%" }}
          >
            {t('cta_alert_success')}
          </Alert>
        </Snackbar>
      </div>

      <div>
        <Snackbar
          className={"successAlert"}
          open={feedbackError}
          autoHideDuration={6000}
          onClose={handleCloseFE}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseFE}
            severity="error"
            sx={{ width: "100%" }}
          >
            {t('cta_alert_error')}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default CTA;
