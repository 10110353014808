
import { useTranslation } from 'react-i18next';
import './error-page.css';

export default function ErrorPage() {

  const {t} = useTranslation();

  return (
    <div id="error-page" >
      <h1><b className="gradient__text">404</b> Ooops!</h1>
      <p>{t("404")}</p>
      <p>
      </p>
    </div>
  );
}