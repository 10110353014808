import React from 'react';
import { Link } from 'react-router-dom';

import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from 'gsap';
import Helderly01 from '../../assets/projects/helderly/Helderly-01.jpg';
import Helderly03 from '../../assets/projects/helderly/Helderly-02.jpg';
import Helderly04 from '../../assets/projects/helderly/Helderly-04.jpg';
import Helderly05 from '../../assets/projects/helderly/Helderly-05.jpg';
import Helderly06 from '../../assets/projects/helderly/Helderly-06.jpg';
import Helderly07 from '../../assets/projects/helderly/Helderly-07.jpg';
import Footer from '../../containers/footer/Footer';
import { Trans, useTranslation } from 'react-i18next';

import './project0.css';

const { useLayoutEffect, useEffect, useRef, useState, forwardRef } = React;

const FadeIn = forwardRef(({ children, stagger = 0, x = 0, y = 0 }, ref) => {
  const el = useRef();
  const animation = useRef();
  

  useLayoutEffect(() => {
    animation.current = gsap.from(el.current.children, {
      opacity: 0,
      stagger,
      x,
      y
    });
  });


  useEffect(() => {

    // forward the animation instance
    if (typeof ref === "function") {
      ref(animation.current);
    } else if (ref) {
      ref.current = animation.current;
    }
  }, [ref]);

  return <span ref={el}>{children}</span>
});

const SlideProject0 = () => {

  gsap.registerPlugin(ScrollTrigger);

  const animation1 = useRef();
  const panels = useRef([]);
  const panelsContainer = useRef();
  const description = useRef();
  const {t} = useTranslation();

  const createPanelsRefs = (panel, index) => {
    panels.current[index] = panel;
  };

  useLayoutEffect(() => {
    const totalPanels = panels.current.length;

    gsap.to(panels.current, {
      xPercent: -100 * (totalPanels - 1),
      ease: "none",
      scrollTrigger: {
        trigger: panelsContainer.current,
        id: '3434',
        pin: true,
        scrub: 1,
        snap: 1 / (totalPanels - 1),
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + panelsContainer.current.offsetWidth
      }
    });
    return () => {
      ScrollTrigger.getById('3434').kill();
    };
  }, []);

  useLayoutEffect(() => {

    gsap.to(description.current, {
      yPercent: -15,
      ease: "none",
      scrollTrigger: {
        trigger: description.current,
        id: '3435',

        scrub: 1,
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + panelsContainer.current.offsetWidth
      }
    });
    return () => {
      ScrollTrigger.getById('3435').kill();
    };
  }, []);

  return (
    <>
      <div className="yasyt__project-helderly__slide" ref={panelsContainer}>

        <Link to='/projects'>
          <h3 className='yasyt__project-helderly__back'>Back</h3>
        </Link>
        <div className='yasyt__project-helderly__text-inside' >
          <FadeIn stagger={0.3} x={-100} ref={animation1}>
            <div ref={description}>
              <Trans>{t("project_1_description")}</Trans>
            </div>
          </FadeIn>
        </div>
        <div className="yasyt__projects-helderly__images" ref={(e) => createPanelsRefs(e, 0)}>
          <img src={Helderly03} alt="Imagen de un hombre de una residencia" />
        </div>
        <div className="yasyt__projects-helderly__images" ref={(e) => createPanelsRefs(e, 1)}>
          <img src={Helderly04} alt="Imagen de un hombre de una residencia" />
        </div>
        <div className="yasyt__projects-helderly__images" ref={(e) => createPanelsRefs(e, 2)}>
          <img src={Helderly05} alt="Imagen de un hombre de una residencia" />
        </div>
        <div className="yasyt__projects-helderly__images" ref={(e) => createPanelsRefs(e, 3)}>
          <img src={Helderly06} alt="Imagen de un hombre de una residencia" />
        </div>
        <div className="yasyt__projects-helderly__images" ref={(e) => createPanelsRefs(e, 4)}>
          <img src={Helderly07} alt="Imagen de un hombre de una residencia" />
        </div>
      </div>
      <Footer />
    </>
  );
}


const Project0 = () => {

  const animation0 = useRef();
  const animation1 = useRef();
  const animation2 = useRef();
  const {t} = useTranslation();

  const [toggleMore, setToggleMore] = useState(true);
  

  return (
    <>
      {toggleMore ? (
        <div>
          <div className="yasyt__project-helderly__inside">
          
            
          
            <div>
              <FadeIn stagger={0.5} x={200} ref={animation2}>
                <h1 className="yasyt__project-helderly__title-inside" >Helderly</h1>
              </FadeIn>
              <FadeIn stagger={0.1} y={100} ref={animation0}>
                <h3 onClick={() => setToggleMore(false)} className='yasyt__project-helderly__more'>More</h3>
              </FadeIn>
              <Link to='/projects'>
                <h3 className='yasyt__project-helderly__back'>Back</h3>
              </Link>

              <div className='yasyt__project-helderly__text'>
                <FadeIn stagger={0.3} x={-100} ref={animation1}>
                <Trans>{t("project_1_title")}</Trans>
                </FadeIn>
              </div>
              
              <img src={Helderly01} alt="Imagen de un hombre de una residencia" />
              
            </div>
            
          </div>
          <Footer />
        </div>
      )
        : <SlideProject0 />}
    </>
  );
}

export default Project0;