import React from 'react';
import './footer.css';
import CTA from "../../components/cta/CTA";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { useTranslation } from 'react-i18next';
import {
  RiInstagramFill,
  RiLinkedinBoxFill,
  RiTwitterFill,
} from "react-icons/ri";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

const Footer = () => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const {t} = useTranslation();

  return (

    <footer id="myFooter">
    <ScrollToTopOnMount />
      <div className="yasyt__footer-main__container">
        <div className="yasyt__footer-main__row">
          <div className="yasyt__footer-main__col-sm-3">
            <h2 className="yasyt__footer-main__logo"><a href="/"> <img src={logo} alt="logo" /> </a></h2>
          </div>
          <div className="yasyt__footer-main__col-sm-2">
            <h5>{t('get-started')}</h5>
            <ul>
              <li><Link to="/">{t('home')}</Link></li>
              <li><Link to="/projects">{t('projects')}</Link></li>
              <li><Link to="/method">{t('method')}</Link></li>

            </ul>
          </div>
          <div className="yasyt__footer-main__col-sm-2">
            <h5>{t('about-us')}</h5>
            <ul>
              <li><Link to="/company">{t('company')}</Link></li>
              <li><Link to="/partners">{t('partners')}</Link></li>
              <li><a href="#bottom" onClick={() => setToggleMenu(!toggleMenu)} >{t('find-us')}</a></li>

            </ul>
          </div>
          <div className="yasyt__footer-main__col-sm-2">
            <h5>{t('support')}</h5>
            <ul>
              <li><Link to="/politica">{t('privacy')}</Link></li>
              <li><Link to='/terminos'>{t('terms')}</Link></li>
            </ul>
          </div>
          <div className="yasyt__footer-main__col-sm-3">
            <div className="yasyt__footer-main__social-networks">
              <a href="https://www.instagram.com/yasytrobotics/"><i><RiInstagramFill className="ins" size={27} /></i></a>
              <a href="https://es.linkedin.com/company/yasyt-robotics"><i><RiLinkedinBoxFill className="lin" size={27} /></i></a>
              <a href="https://twitter.com/RoboticsYasyt"><i><RiTwitterFill className="twi" size={27} /></i></a>
            </div>

            <Button className="yasyt__footer-mail__button" type="button" onClick={() => setIsOpen(!isOpen)}>{t("contact")}</Button>
            {isOpen && <CTA setIsOpen={setIsOpen} />}

            {/* <button type="button" className="btn btn-default">Contact us</button> */}
          </div>
        </div>
      </div>
      {toggleMenu && (
          <>
            <div className="">
            <iframe title="jklsdhafaiksufo" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2992.4112550903415!2d2.127879315674642!3d41.40859050264281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a49816c5cc995f%3A0xe6d45d60cbf71998!2sLa%20Salle%20Campus%20Barcelona-URL!5e0!3m2!1ses!2ses!4v1660301295566!5m2!1ses!2ses" width="100%" height="450" loading="lazy"></iframe>
            </div>
          </>
        )}
      <div id="bottom" className="yasyt__footer-main__copyright" ><p>Copyright © 2022 YASYT Robotics </p></div>
    </footer>
  )
};

export default Footer;