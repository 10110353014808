import React, { useRef, useLayoutEffect } from 'react';
import './feature2.css';
import Psicomotriu from '../../assets/home/social-01-web.jpg';
import Hospital from '../../assets/home/social-02-web.jpg';
import { gsap } from "gsap";
import{ ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';

function Feature2() {
  gsap.registerPlugin(ScrollTrigger);
  const psico = useRef();
  const hospital = useRef();
  const socialTitle = useRef();
  let tl_psico = useRef();
  let tl_hospital = useRef();
  const {t} = useTranslation();
 /* gsap.timeline({
      scrollTrigger: {
        trigger: ".yasyt__features-team__office",
        markers: false,
        start: "500 600",
        end: "+=900 top",
        pin: ".yasyt__features-team__office",
        scrub: true,
      }
  }); */
  useLayoutEffect(() => {   

  tl_psico.current = gsap.timeline()
  .from((psico.current), {
    scrollTrigger: {
      id:'3426',
      trigger: psico.current,
      markers: false,
      start: "top bottom",
      end: "+=600 top",
      scrub: true,
    },
    x: "+=20%",
  })
  .to((psico.current), {
    scrollTrigger: {
      id:'3426',
      trigger: psico.current,
      markers: false,
      start: "bottom bottom",
      end: "+=500 center",
      scrub: true,
      pin: psico.current,
    }
  });
  return () => {  
    ScrollTrigger.getById('3426').kill();
  };
}, []);

useLayoutEffect(() => { 
  tl_hospital.current = gsap.timeline()
  .from((hospital.current), {
    scrollTrigger: {
      id:'3427',
      trigger: hospital.current,
      markers: false,
      start: "top bottom",
      end: "+=600 top",
      scrub: true,
    },
    x: "-=20%",
  })
  
  .from((hospital.current), {
    scrollTrigger: {
      id:'3427',
      trigger: hospital.current,
      markers: false,
      start: "bottom bottom",
      end: "+=500 center",
      scrub: true,
      pin: hospital.current,
    }
  });
  return () => {  
    ScrollTrigger.getById('3427').kill();
  };
  }, []);

  useLayoutEffect(() => {
    gsap.from(socialTitle.current, {
      scale: 10,
      duration: 10,
      delay: 0,
      ease: "elastic.out(1, 0.3)",
      scrollTrigger: {
        id:'3419',
        trigger: ".yasyt__features-team__text",
        start:"top bottom",
        end: "+=200 top",
        markers: false,
        scrub:true
      }
    });
    return () => {  
      ScrollTrigger.getById('3419').kill();
    };
  }, 
  []);
  
  return (
  <>
    <div className="yasyt__features-team">
      
        
      <div className="yasyt__features-team__gym" >
        <img src={Psicomotriu} alt="Assistant" ref={psico}/>
      </div>
      <div className="yasyt__features-team__hospital" > 
        <img src={Hospital} alt="hospital" ref={hospital}/>
      </div>
      <div className="yasyt__features-team__text" >
          <h1 ref={socialTitle}>{t("social")}</h1>
      </div>
{/*       <div className='yasyt__features-social__tafop'>
        <div className='yasyt__features-social__tafop-img'> */}
          
        </div>
{/*       </div>
    </div> */}
  </>);
}

export default Feature2
