import React, { useRef, useState, useLayoutEffect } from 'react';
import "./header.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CTA from "../../components/cta/CTA";
import { useTranslation } from 'react-i18next';

import {
  RiInstagramFill,
  RiLinkedinBoxFill,
  RiTwitterFill,
} from "react-icons/ri";

function Header(){
  gsap.registerPlugin(ScrollTrigger);
  
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const frameCount = 104;
  const currentFrame = index => (
    `https://www.yasyt.com/home_images/pepper_web_01/VideoPepperWeb-01_${(index + 1).toString().padStart(4, '0')}.jpg`
  );

  const images = []
  const airpods = {
    frame: 0
  };

  for (let i = 0; i < frameCount; i++) {
    const img = new Image();
    img.src = currentFrame(i);
    console.log(currentFrame[i])
    images.push(img);
  }

  useLayoutEffect(() => {
    const element = ref.current;
    const canvas = element.querySelector(".box");
    const context = canvas.getContext("2d");

    canvas.width = 1920;
    canvas.height = 1080;

    gsap.to(airpods, {
      frame: frameCount - 1,
      snap: "frame",
      ease: "none",
      scrollTrigger: {
        id: '3420',
        scrub: 0.1,
        start: "top top",
        end: "+=3800",
        pin:".box",
        markers:false
      }, onUpdate: render  // use animation onUpdate instead of scrollTrigger's onUpdate
    })
    // Clean up before unmounting the component/page
    images[0].onload = render

    function render() {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(images[airpods.frame], 0, 0); 
    }

    return () => {  
      ScrollTrigger.getById('3420').kill();
    };
  });

  return(
    
    <div className="yasyt__header section__padding" id="home">
    {/* {isOpen && <CTA setIsOpen={setIsOpen} />} */}
      <div className="yasyt__header-social">
      <a href="https://www.instagram.com/yasytrobotics/"><RiInstagramFill color="#fff" size={27} /></a>
      <a href="https://es.linkedin.com/company/yasyt-robotics"><RiLinkedinBoxFill color="#fff" size={27} /></a>
      <a href="https://twitter.com/RoboticsYasyt"><RiTwitterFill color="#fff" size={27} /></a>
      </div>
      <div className="yasyt__header-content">
        <h1 className="gradient__text yasyt__header-semibold__text">
              technological{" "}
        </h1>
        <h1 className="gradient__text yasyt__header-thinitalic__text">
              assistance
        </h1>
        <h1 className="gradient__text">
          <span className="yasyt__header-normal__text">focused</span>
          <span className="yasyt__header-thin__text"> on</span>
          <span className="yasyt__header-bold__text"> people</span>
        </h1>
        <div className="yasyt__header-content__input">
          <button className="yasyt__header-content__button" type="button" onClick={() => setIsOpen(!isOpen)}>{t('contact')}</button>
          {isOpen && <CTA setIsOpen={setIsOpen} />}
        </div>
      </div>
      <div className="upperBox">
        <div ref={ref}>
          <canvas className="box"/>
        </div>
      </div>
    </div>
  );
}
export default Header;
