import React, { useRef, useLayoutEffect } from 'react';
import './feature5.css';
import { gsap } from "gsap";
import{ ScrollTrigger } from "gsap/ScrollTrigger";



function Feature5(){
  gsap.registerPlugin(ScrollTrigger);

  const ref3 = useRef(null);
  const frameCount3 = 39;
  const currentFrame3 = index => (
    `https://www.yasyt.com/home_images/pepper_web_03/VideoPepperWeb-03-${(index + 1).toString().padStart(4, '0')}.jpg`
  );

  const images3 = []
  const airpods3 = {
    frame: 0
  };

  for (let i = 0; i < frameCount3; i++) {
    const img3 = new Image();
    img3.src = currentFrame3(i);
    /* console.log(currentFrame3[i]) */
    images3.push(img3);
  }

  useLayoutEffect(() => {
    const element3 = ref3.current;
    const canvas3 = element3.querySelector(".box3");
    const context3 = canvas3.getContext("2d");

    canvas3.width = 1920;
    canvas3.height = 1080;

    gsap.to(airpods3, {
      frame: frameCount3 - 1,
      snap: "frame",
      ease: "none",
      scrollTrigger: {
        id:'3416',
        scrub: 0.1,
        start: "top top",
        end: "+=800",
        pin:".box3",
        markers:false
      }, onUpdate: render3  // use animation onUpdate instead of scrollTrigger's onUpdate
    })
    // Clean up before unmounting the component/page
    images3[0].onload = render3

    function render3() {
      context3.clearRect(0, 0, canvas3.width, canvas3.height);
      context3.drawImage(images3[airpods3.frame], 0, 0); 
    }

    return () => {  
      ScrollTrigger.getById('3416').kill();
    };

  });
  
  return(
  <div className="yasyt__features-socialite">
    <div ref={ref3} >
      <canvas className='box3'/>
    </div>
  </div>
  );
}

export default Feature5
