import React, {
  useRef,
  useEffect,
  useState /* , useLayoutEffect */,
} from "react";
import { Link } from "react-router-dom";

import Guidyt from "../../assets/projects/guidyt/guidyt-13.jpg";
import Helderly from "../../assets/projects/helderly/Helderly-03.jpg";
import close2u from "../../assets/projects/close2u/closetoyou-01.jpg";
import Robroom from "../../assets/projects/robroom/RobRoom-15.jpg";
import Robhome from "../../assets/projects/robhome/robhome-01.jpg";
import Mynewfriend from "../../assets/projects/mynewfriend/mynewfriend-01.jpg";
import "./projects.css";
import Footer from "../footer/Footer";
import useLocalStorage from "../../hooks/use-localstorage";

const Projects = () => {
  const panelsContainer = useRef();
  const helderlyscrollpin = useRef();
  const guidytscrollpin = useRef();
  const close2uscrollpin = useRef();
  const robroomscrollpin = useRef();
  const robhomescrollpin = useRef();
  const mynewfriendscrollpin = useRef();
  const [position, setPosition] = useLocalStorage("position", 0);
  const [scrollValue, setScrollValue] = useState("0");

/*   function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
  } */

  const handlePosition = (evnt) => {
    setPosition(scrollValue);
    
    console.log("Position: ", position);
  };

  useEffect(() => {

    if(position===0){
      
    } else {
      window.scrollTo(0, position)
      setPosition(0);
    }
    

    const onScroll = (e) => {
      setScrollValue(e.target.documentElement.scrollTop);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollValue, position, setPosition]);

  return (
    <>
      {/* <ScrollToTopOnMount /> */}
      <div className="yasyt__projects" ref={panelsContainer}>
        <div ref={helderlyscrollpin} className="yasyt__projects-helderly">
          <Link
            to="/projects/helderly"
            value={0}
            onClick={handlePosition}
          >
            <h1 className="yasyt__projects-helderly__title">Helderly</h1>
            <img src={Helderly} alt="Imagen de un hombre de una residencia" />
          </Link>
        </div>

        <div ref={guidytscrollpin} className="yasyt__projects-guidyt">
          <Link
            to="/projects/guidyt"
            value={1080}
            onClick={handlePosition}
          >
            <h1 className="yasyt__projects-guidyt__title">Guidyt</h1>
            <img src={Guidyt} alt="Imagen de un hombre de una residencia"  />
          </Link>
        </div>

        <div ref={close2uscrollpin} className="yasyt__projects-close2u" >
          <Link
            to="/projects/close2u"
            title="2160" 
            onClick={handlePosition}
            
            
          >
            <h1 className="yasyt__projects-close2u__title">close2u</h1>
            <img src={close2u} alt="Imagen de un hombre de una residencia" />
          </Link>
        </div>

        <div ref={robroomscrollpin} className="yasyt__projects-robroom">
          <Link
            to="/projects/robroom"
            value={3240}
            onClick={handlePosition}
          >
            <h1 className="yasyt__projects-robroom__title">Robroom</h1>
            <img src={Robroom} alt="Imagen de un hombre de una residencia" />
          </Link>
        </div>

        <div ref={robhomescrollpin} className="yasyt__projects-robhome">
          <Link
            to="/projects/robhome"
            value={4320}
            onClick={handlePosition}
          >
            <h1 className="yasyt__projects-robhome__title">Robhome</h1>
            <img src={Robhome} alt="Imagen de un hombre de una residencia" />
          </Link>
        </div>

        <div ref={mynewfriendscrollpin} className="yasyt__projects-mynewfriend">
          <Link
            to="/projects/mynewfriend"
            value={5400}
            onClick={handlePosition}
          >
            <h1 className="yasyt__projects-mynewfriend__title">
              My new friend
            </h1>
            <img
              src={Mynewfriend}
              alt="Imagen de un hombre de una residencia"
            />
          </Link>
        </div>
        <div className="yasyt__projects-footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Projects;
