import React, { useRef, useLayoutEffect } from 'react';
import './feature0.css';
import { gsap } from "gsap";
import{ ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';

function Feature0(){
  const robotsTitle = useRef();
  const robotsParagraph = useRef();
  const robotsSlogan = useRef();
  const {t} = useTranslation();

  useLayoutEffect(() => {
    gsap.from(".robots", {
      scale: 10,
      duration: 0.1,
      delay: 0,
      ease: "elastic.out(1, 0.3)",
      scrollTrigger: {
        id:'3421',
        trigger: ".robots",
        start: "center 110%",
        end: "center 75%",
        markers: false,
        scrub:true
      }
    });
    return () => {  
      ScrollTrigger.getById('3421').kill();
    };
  }, 
  []);

  useLayoutEffect(() => {


    gsap.to(robotsParagraph.current, {
      scrollTrigger: {
        id:'3422',
        trigger: robotsParagraph.current,
        markers: false,
        start: "40% 40%",
        end: "+=900 top",
        pin: ".yasyt__features-robots__text_paragraph",
        scrub: true,
      },
      opacity: 1,
      

    });
    return () => {  
      ScrollTrigger.getById('3422').kill();
    };
  }, 
  []);



  useLayoutEffect(() => {
    gsap.to(robotsSlogan.current, {
      
      scrollTrigger: {
        id: "3423",
        trigger: ".yasyt__features-robots__text_slogan",
        markers: false,
        start: "top 60%",
        end: "+=300 center",
        pin: ".yasyt__features-robots__text_slogan",
        scrub: 0.1,
        
        
      },
      opacity:1,
      scale:1.5,
      duration:5
    });
    
    return () => {  
      ScrollTrigger.getById('3423').kill();
    };
  }, 
  []);

  return (
    <>
      <div className="yasyt__features-robots">
        <div className="yasyt__features-robots__text_title">  
          <h1 className="robots" ref={robotsTitle}>{t('robots')}</h1>
        </div>
        <div className="yasyt__features-robots__text">
          <div className='yasyt__features-robots__text_paragraph' ref={robotsParagraph}>
            <p>{t("robots-par-1")}</p><br />
            <p>{t("robots-par-2")}</p><br />
            <p>{t("robots-par-3")}</p>
          </div>
        </div>
        <div className="yasyt__features-robots__text_slogan" ref={robotsSlogan}>
          <p>{t("robots-slogan")}</p>
        </div>
      </div>
    </>
  );
}

export default Feature0;