import React, { useRef, useLayoutEffect } from 'react';
import './feature3.css';
import gsap from 'gsap';
import Hands from '../../assets/home/social-05-web.jpg';
import{ ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';

function Feature3() {
  gsap.registerPlugin(ScrollTrigger);
  const handshake = useRef();
  const hanshakeText = useRef();
  const {t} = useTranslation();

  useLayoutEffect(() => { 
    gsap.from((handshake.current), {
      scrollTrigger: {
        id:'3417',
        trigger: handshake.current,
        markers: false,
        start: "50% 50%",
        end: "+=2000 50%",
        scrub: true,
        pin: handshake.current,
      }
    });

    return () => {  
      ScrollTrigger.getById('3417').kill();
    };
  }, []);

  useLayoutEffect(() => { 
    gsap.from((hanshakeText.current), {
      scrollTrigger: {
        id:'3418',
        trigger: hanshakeText.current,
        markers: false,
        start: "50% 50%",
        end: "+=2300% 50%",
        scrub: true,
        pin: hanshakeText.current,
      }
    })
    
    
    return () => {  
      ScrollTrigger.getById('3418').kill();
    };
  }, []);


  return (
    <>
      <div className="yasyt__features-ux">
        <div className='yasyt__features-social__tafop'>
          <img src={Hands} alt="hands shake" ref={handshake}/>

        </div>
        <div className='yasyt__features-social__tafop-text' ref={hanshakeText}>
                  <p >{t("developers")}</p>
        </div>
      </div>
    </>
  );
}

export default Feature3