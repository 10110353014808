import React from 'react';
import { Link } from 'react-router-dom';

import{ ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from 'gsap';
import close2u01 from '../../assets/projects/close2u/closetoyou-01.jpg';
import close2u02 from '../../assets/projects/close2u/closetoyou-02.jpg';
import close2u03 from '../../assets/projects/close2u/closetoyou-03.jpg';
import close2u04 from '../../assets/projects/close2u/closetoyou-04.jpg';
import Footer from '../../containers/footer/Footer';
import { Trans, useTranslation } from 'react-i18next';

import './project2.css';

const { useLayoutEffect, useEffect, useRef, useState, forwardRef } = React;

const FadeIn = forwardRef(({ children, stagger = 0, x = 0, y=0 }, ref) => {
  const el = useRef();
  const animation = useRef();
  
  
  useLayoutEffect(() => {    
    animation.current = gsap.from(el.current.children, { 
      opacity: 0,
      stagger, 
      x,
      y
    });        
  });

  
  useEffect(() => {
    
    // forward the animation instance
    if (typeof ref === "function") {
      ref(animation.current);
    } else if (ref) {
      ref.current = animation.current;
    }
  }, [ref]);
  
  return <span ref={el}>{children}</span>
});

const SlideProject2 = ()=> {

  gsap.registerPlugin(ScrollTrigger);

  const animation1 = useRef();
  const panels = useRef([]);
  const panelsContainer = useRef();
  const description = useRef();
  const {t} = useTranslation();

  const createPanelsRefs = (panel, index) => {
    panels.current[index] = panel;
  };

  useLayoutEffect(() => {
    const totalPanels = panels.current.length;

    gsap.to(panels.current, {
      xPercent: -100 * (totalPanels - 1),
      ease: "none",
      scrollTrigger: {
        trigger: panelsContainer.current,
        id:'3438',
        pin: true,
        scrub: 1,
        snap: 1 / (totalPanels - 1),
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + panelsContainer.current.offsetWidth
      }
    });
    return () => {  
      ScrollTrigger.getById('3438').kill();
    };
  }, []);

  useLayoutEffect(() => {

    gsap.to(description.current, {
      yPercent: -15,
      ease: "none",
      scrollTrigger: {
        trigger: description.current,
        id:'3439',
        
        scrub: 1,
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + panelsContainer.current.offsetWidth
      }
    });
    return () => {  
      ScrollTrigger.getById('3439').kill();
    };
  }, []);

  return (
    <>
    <div className="yasyt__project-close2u__slide" ref={panelsContainer}>
      
        <Link to='/projects'>
          <h3 className='yasyt__project-close2u__back'>Back</h3>
        </Link>
        <div className='yasyt__project-close2u__text-inside' >  
          <FadeIn stagger={0.3} x={-100} ref={animation1}>
          <div ref={description}>
            <Trans>{t("project_3_description")}</Trans>
            </div>
          </FadeIn>
        </div>
        <div className="yasyt__projects-close2u__images" ref={(e) => createPanelsRefs(e, 0)}>  
          <img src={close2u01} alt="Imagen de un hombre de una residencia"/>
        </div>
        <div className="yasyt__projects-close2u__images" ref={(e) => createPanelsRefs(e, 1)}>   
          <img src={close2u02} alt="Imagen de un hombre de una residencia"/>
        </div>
        <div className="yasyt__projects-close2u__images" ref={(e) => createPanelsRefs(e, 2)}>   
          <img src={close2u03} alt="Imagen de un hombre de una residencia"/>
        </div>
        <div className="yasyt__projects-close2u__images" ref={(e) => createPanelsRefs(e, 3)}>   
          <img src={close2u04} alt="Imagen de un hombre de una residencia"/>
        </div>
    </div>
    <Footer />
      </>
  );
 }


const Project2 = () => {

  const animation0 = useRef();
  const animation1 = useRef();
  const animation2 = useRef();
  const {t} = useTranslation();

  const [toggleMore, setToggleMore] = useState(true);

  return (
    <>
    {toggleMore ? (
      <>
      <div className="yasyt__project-close2u__inside">
        <div>
          <FadeIn stagger={0.5} x={200} ref={animation2}>
            <h1 className="yasyt__project-close2u__title-inside" >close2u</h1>
          </FadeIn>
          <FadeIn stagger={0.1} y={100} ref={animation0}>
            <h3 onClick={()=>setToggleMore(false)} className='yasyt__project-close2u__more'>More</h3>
          </FadeIn>
          <Link to='/projects'>
            <h3 className='yasyt__project-close2u__back'>Back</h3>
          </Link>
          
          <div className='yasyt__project-close2u__text'>  
            <FadeIn stagger={0.3} x={-100} ref={animation1}>
              <Trans>{t("project_3_title")}</Trans>
            </FadeIn>
          </div>
          <img src={close2u01} alt="Imagen de un hombre de una residencia"/>
        </div>
      </div>
      <Footer />
      </>
    ) 
    : <SlideProject2 />} 
</>
  );
}

export default Project2;